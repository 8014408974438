<template>
  <div class="index2-container">
    <el-container>
      <el-header class="index2-header">
        <div class="index2-header-container">
          <div class="index2-header-left">
            <div class="index2-header-img">
              <img src="../assets/images/login/logo.png" class="index2-pic" />
            </div>
            <div class="index2-header-nav" v-show="isShow == 0">
              <el-menu
                :default-active="activeIndex.toString()"
                class="el-menu-demo"
                mode="horizontal"
                active-text-color="#4F81F0"
                @select="handleSelect"
              >
                <el-menu-item
                  v-for="(item, index) in tabData"
                  :key="index"
                  :index="item.id.toString()"
                  >{{ item.title }}</el-menu-item
                >
              </el-menu>
            </div>
          </div>
          <div class="index2-header-right" v-show="isShow == 0">
            <el-dropdown placement="bottom" trigger="hover">
              <div>
                <span>
                  {{ respondentname
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item>修改密码</el-dropdown-item> -->
                <el-dropdown-item @click.native="outLogin"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="route-nav" v-show="activeIndex == 1 && routejump == true">
          <el-page-header
            @back="goBack"
            :content="initialTitle"
          ></el-page-header>
          <div
            :class="[
              'route-title',
              item == '案件详情' && changeDetailTitle == true
                ? 'route-title-light'
                : '',
            ]"
            v-show="isShow == 0"
            v-for="(item, index) in routeTitle"
            :key="index"
          >
            <div class="route-title-line">/</div>
            {{ item }}
          </div>
        </div>
        <div
          class="route-nav"
          v-show="activeIndex == 0 && routearbitratjump == true"
        >
          <el-page-header
            @back="goarbitratBack"
            :content="arbitratTitle"
          ></el-page-header>
          <div
            class="route-title"
            v-show="isShow == 0"
            v-for="(item, index) in routeArbitratTitle"
            :key="index"
          >
            <div class="route-title-line">/</div>
            {{ item }}
          </div>
        </div>
        <div
          class="index2-main-nav"
          v-show="
            (isShow == 1 && routejump == false && routearbitratjump == false)"
        >
          <el-menu
            :default-active="activeIndex.toString()"
            class="el-menu-demo"
            mode="horizontal"
            active-text-color="#4F81F0"
            @select="handleSelect"
          >
            <el-menu-item
              v-for="(item, i) in tabData"
              :key="i"
              :index="item.id.toString()"
              >{{ item.title }}</el-menu-item
            >
          </el-menu>
        </div>
        <div
          class="index2-main"
          v-show="activeIndex == 0 && routearbitratjump == false"
        >
          <div class="index2-main-container">
            <div class="index2-main-top" v-show="isShow == 0">
              <div class="index2-main-top-title">仲裁案件</div>
            </div>

            <div class="index2-main-body">
              <el-table
                ref="multipleTable"
                :data="
                  arbitratList.slice(
                    (arbitratCurrentPage - 1) * arbitratPagesize,
                    arbitratCurrentPage * arbitratPagesize
                  )
                "
                tooltip-effect="dark"
                style="width: 100"
                :class="{ 'empty-table': !arbitratList.length > 0 }"
              >
                <el-table-column
                  show-overflow-tooltip
                  min-width="50"
                  label="案号"
                >
                  <template slot-scope="scope">
                    {{scope.row.caseNo ? scope.row.caseNo : '-'}}
                  </template>
                </el-table-column>
                <el-table-column min-width="25" label="状态" prop="caseStatusDesc">
                  <template slot-scope="scope">
                    {{scope.row.caseStatusDesc ? scope.row.caseStatusDesc : '-'}}
                  </template>
                </el-table-column>
                <el-table-column label="操作" min-width="25">
                  <template slot-scope="scope">
                    <el-button
                      @click="goArbitratDetail(scope.row)"
                      type="text"
                      size="small"
                      v-show="isShow == 0"
                      >查看案件详情</el-button
                    >
                    <el-button
                      @click="goArbitratDetail(scope.row)"
                      type="text"
                      size="small"
                      v-show="isShow == 1"
                      >查看</el-button
                    >
                  </template>
                </el-table-column>
                <!-- 没有数据 -->
                <template slot="empty">
                  <div class="empty-img">
                    <img src="../assets/images/index/empty.png" />
                  </div>
                  <div class="empty-text">暂无相关数据</div>
                </template>
              </el-table>
              <el-pagination
                @size-change="arbitratHandleSizeChange"
                @current-change="arbitratHandleCurrentChange"
                :current-page="arbitratCurrentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="arbitratPagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="arbitratTotalCount"
                v-show="arbitratList.length > 0 && isShow == 0"
              >
              </el-pagination>
              <div class="mobile-pagination" v-show="isShow == 1">
                <el-pagination
                  :class="{ 'mobile-pagination': isShow == 1 }"
                  @size-change="arbitratHandleSizeChange"
                  @current-change="arbitratHandleCurrentChange"
                  :current-page="arbitratCurrentPage"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="arbitratPagesize"
                  layout="slot"
                  :total="arbitratTotalCount"
                  v-show="arbitratList.length > 0"
                >
                  <template>
                    <div
                      :class="[
                        'pagination-left',
                        arbitratPagePrevBtn ? 'pagePrevBtn-disabled' : '',
                      ]"
                      @click="arbitratPrev"
                    >
                      <i class="el-icon-back left-icon"></i>
                      <span>上一页</span>
                    </div>
                    <el-input v-model="arbitratCurrentPage" readonly></el-input>
                    <div
                      :class="[
                        'pagination-right',
                        arbitratPageNextBtn ? 'pageNextBtn-disabled' : '',
                      ]"
                      @click="arbitratNext"
                    >
                      <span>下一页</span>
                      <i class="el-icon-right right-icon"></i>
                    </div>
                  </template>
                </el-pagination>
              </div>
            </div>
          </div>

          <div class="index2-main-footer" v-show="isShow == 1">
            <el-button
              type="text"
              @click.native="outLogin"
              class="index2-login-out"
              >退出登录</el-button
            >
          </div>
        </div>
        <div
          class="index2-main"
          v-show="activeIndex == 1 && routejump == false"
        >
          <div class="index2-main-container">
            <div class="index2-main-top" v-show="isShow == 0">
              <div class="index2-main-top-title">调解案件</div>
            </div>

            <div class="index2-main-body">
              <el-table
                ref="multipleTable"
                :data="
                  mdiationList.slice(
                    (currentPage - 1) * pagesize,
                    currentPage * pagesize
                  )
                "
                tooltip-effect="dark"
                style="width: 100"
                :class="{ 'empty-table': !mdiationList.length > 0 }"
              >
                <el-table-column
                  show-overflow-tooltip
                  min-width="50"
                  prop="caseNo"
                  label="案号"
                >
                </el-table-column>
                <el-table-column min-width="25" label="状态" prop="statusDes">
                </el-table-column>
                <el-table-column label="操作" min-width="25">
                  <template slot-scope="scope">
                    <el-button
                      @click="goCaseDetail(scope.row)"
                      type="text"
                      size="small"
                      v-show="isShow == 0"
                      >查看案件详情</el-button
                    >
                    <el-button
                      @click="goCaseDetail(scope.row)"
                      type="text"
                      size="small"
                      v-show="isShow == 1"
                      >查看</el-button
                    >
                  </template>
                </el-table-column>
                <!-- 没有数据 -->
                <template slot="empty">
                  <div class="empty-img">
                    <img src="../assets/images/index/empty.png" />
                  </div>
                  <div class="empty-text">暂无相关数据</div>
                </template>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="mdiationList.length"
                v-show="mdiationList.length > 0 && isShow == 0"
              >
              </el-pagination>
              <div class="mobile-pagination" v-show="isShow == 1">
                <el-pagination
                  :class="{ 'mobile-pagination': isShow == 1 }"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pagesize"
                  layout="slot"
                  :total="mdiationList.length"
                  v-show="mdiationList.length > 0"
                >
                  <template>
                    <div
                      :class="[
                        'pagination-left',
                        pagePrevBtn ? 'pagePrevBtn-disabled' : '',
                      ]"
                      @click="prev"
                    >
                      <i class="el-icon-back left-icon"></i>
                      <span>上一页</span>
                    </div>
                    <el-input v-model="currentPage" readonly></el-input>
                    <div
                      :class="[
                        'pagination-right',
                        pageNextBtn ? 'pageNextBtn-disabled' : '',
                      ]"
                      @click="next"
                    >
                      <span>下一页</span>
                      <i class="el-icon-right right-icon"></i>
                    </div>
                  </template>
                </el-pagination>
              </div>
            </div>
          </div>

          <div class="index2-main-footer" v-show="isShow == 1">
            <el-button
              type="text"
              @click.native="outLogin"
              class="index2-login-out"
              >退出登录</el-button
            >
          </div>
        </div>

        <div
          class="route-content"
          v-show="activeIndex == 1 && routejump == true"
        >
          <router-view></router-view>
        </div>
        <div
          class="route-content"
          v-show="activeIndex == 0 && routearbitratjump == true"
        >
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "Index2",
  // components: {CaseDetail},
  data() {
    return {
      activeIndex: "1",
      // activeIndex2: "1",
      currentPage: 1,
      pagesize: 10, //每页的数据条数
      totalCount: 0,
      arbitratCurrentPage: 1,
      arbitratPagesize: 10, //每页的数据条数
      arbitratTotalCount: 0,
      screenWidth: document.body.clientWidth, // 设备屏幕宽度
      initialTitle: "调解案件",
      routeTitle: ["案件详情"], // 路由标题

      arbitratTitle: "仲裁案件",
      routeArbitratTitle: ["案件详情"],

      isShow: "",
      pagePrevBtn: false,
      pageNextBtn: false,

      arbitratPagePrevBtn: false,
      arbitratPageNextBtn: false,
      routejump: false, // 跳转路径
      routearbitratjump: false, // 跳转路径
      respondentname: "", // 被申请人登陆用户名
      changeDetailTitle: false, // 路径变成调解方案时更改路径案件详情字眼颜色

      tabData: [
        {
          id: "0",
          title: "仲裁案件",
        },
        {
          id: "1",
          title: "调解案件",
        },
      ],

      id: "",
      routePath: "",


      uuid: '',

      // 仲裁案件列表
      arbitratList: [
        // {
        //   acceptancenumber: "景德镇仲网调字【2022】第1号", // 受理号
        //   status: "待答复", // 状态
        // },
      ],

      // 调解案件列表
      mdiationList: [
        // {
        //   acceptancenumber: "景德镇仲网调字【2022】第1号", // 受理号
        //   status: "待答复", // 状态
        // },
      ],
    };
  },
  mounted() {
    this.getArbitration()
    this.getRespondent();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    if (this.screenWidth < 549) {
      this.isShow = 1;
      if (this.$route.path == "/casedetail/scheme") {
        this.initialTitle = "调解方案";
      } else if (this.$route.path == "/casedetail") {
        this.initialTitle = "案件详情";
      } else {
        this.initialTitle = "调解案件";
      }

      if (this.$route.path == "/arbitratdetail") {
        this.arbitratTitle = "案件详情";
      } else {
        this.arbitratTitle = "仲裁案件";
      }
    } else {
      this.isShow = 0;
    }

    if (this.$route.path == "/casedetail") {
      this.activeIndex = 1;
      this.routejump = true;
    }
    if (this.$route.path == "/arbitratdetail") {
      this.activeIndex = 0;
      this.routearbitratjump = true;
    }
    if (this.$route.path == "/casedetail/scheme") {
      this.routeTitle.push("调解方案");
      this.changeDetailTitle = true;
    } else {
      this.changeDetailTitle = false;
    }
  },
  created() {
    this.respondentname = sessionStorage.getItem("respondentname");
    // console.log(this.screenWidth);
    if (this.screenWidth < 549) {
      this.isShow = 1;
    } else {
      this.isShow = 0;
    }

    let arbitratListLength =
      parseInt(this.arbitratList.length / this.arbitratPagesize) + 1;
    if (arbitratListLength == 1) {
      this.arbitratPagePrevBtn = true;
      this.arbitratPageNextBtn = true;
    } else {
      this.arbitratPagePrevBtn = true;
      this.arbitratPageNextBtn = false;
    }

    let mdiationListLength =
      parseInt(this.mdiationList.length / this.pagesize) + 1;
    if (mdiationListLength == 1) {
      this.pagePrevBtn = true;
      this.pageNextBtn = true;
    } else {
      this.pagePrevBtn = true;
      this.pageNextBtn = false;
    }
  },
  watch: {
    /* 监听*/
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth < 549) {
        this.isShow = 1;
        if (this.$route.path == "/casedetail/scheme") {
          this.initialTitle = "调解方案";
        } else if (this.$route.path == "/casedetail") {
          this.initialTitle = "案件详情";
        } else {
          this.initialTitle = "调解案件";
        }

        if (this.$route.path == "/arbitratdetail") {
          this.arbitratTitle = "案件详情";
        } else {
          this.arbitratTitle = "仲裁案件";
        }
      } else {
        this.isShow = 0;
      }
    },

    $route: function (newUrl, oldUrl) {
      // console.log(newUrl, oldUrl);
      if (this.isShow == 1 && newUrl.path == "/casedetail") {
        this.initialTitle = "案件详情";
      } else if (this.isShow == 1 && newUrl.path == "/casedetail/scheme") {
        this.initialTitle = "调解方案";
      } else {
        this.initialTitle = "调解案件";
      }

      if (this.isShow == 1 && newUrl.path == "/arbitratdetail") {
        this.arbitratTitle = "案件详情";
        this.activeIndex = 0;
        this.routejump = false;
        this.routearbitratjump = true;
      } else {
        this.arbitratTitle = "仲裁案件";
      }
      // this.routePath = newUrl.path;
      // sessionStorage.setItem("route", newUrl.path);

      if (newUrl.path == "/index2") {
        this.routejump = false;
        this.routearbitratjump = false;
      }
      if (newUrl.path == "/casedetail") {
        this.routejump = true;
        sessionStorage.setItem("route", newUrl.path);
      }
      if (newUrl.path == "/arbitratdetail") {
        this.routearbitratjump = true;
        sessionStorage.setItem("route", newUrl.path);
      }
      if (newUrl.path == "/casedetail/scheme") {
        // this.routejump = true;log
        this.routeTitle.push("调解方案");
        this.changeDetailTitle = true;
      } else {
        this.routeTitle = [];
        this.routeTitle.push("案件详情");
        this.changeDetailTitle = false;
      }
    },
  },
  methods: {
    // 被申请人仲裁案件列表
    getArbitration(){
      this.$axios
        // .post(`/web/web/caseInfo/queryList`)
        .post(`/web/web/caseInfo/queryList?page=${this.arbitratCurrentPage}&rows=${this.arbitratPagesize}`)
        .then((res) => {
          if (res.data.code == 0) {
            this.arbitratTotalCount = res.data.count;
            this.arbitratList = res.data.data;
          } else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },
    // 被申请人调解案件列表
    getRespondent() {
      this.$axios
        .get(`/web/web/respondent/caseList?page=${this.currentPage}&rows=${this.pagesize}`)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.mdiationList = res.data.data;
          }
           else if (res.data.code == 2) {
            this.$message({
              message: res.data.msg,
            });
            setTimeout((res) => {
              this.$router.push("/");
            }, 2000);
          }
           else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: "请求异常，服务器出错",
          });
        });
    },
    // 退出登陆
    outLogin() {
      sessionStorage.removeItem("respondentname");
      this.$message({
        message: "您已退出登陆，请重新登陆！",
        type: "success",
      });
      this.$router.replace("/");
    },
    // 仲裁案件详情
    goArbitratDetail(row) {
      sessionStorage.setItem("arbitratCaseId", row.uuid);
      // console.log(row);
      this.uuid = row.uuid;
      this.routearbitratjump = true;
      this.$router.push({ name: "ArbitratDetail", query: { uuid: row.uuid } });
    },

    // 调解案件详情
    goCaseDetail(row) {
      sessionStorage.setItem("mediateCaseId", row.id);
      // console.log(row);
      this.id = row.id;
      this.routejump = true;
      this.$router.push({ name: "CaseDetail", query: { id: row.id } });
    },
    goarbitratBack() {
      this.$router.push({ name: "Index2" });
    },
    goBack() {
      // console.log('go back');
      // this.$router.go(-1);
      if (this.$route.path == "/casedetail/scheme") {
        this.$router.push({ name: "CaseDetail", query: { id: this.id } });
      }
      if (this.$route.path == "/casedetail") {
        this.$router.push({ name: "Index2" });
      }
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      let $route = sessionStorage.getItem("route");
      if (key == 1) {
        this.getRespondent();
        if (this.routejump == true) {
          if ($route == "/casedetail") {
            this.$router.push({ name: "CaseDetail", query: { id: this.id } });
          } else if ($route == "/casedetail/scheme") {
            this.$router.push({ name: "Scheme", query: { id: this.id } });
          }
        } else {
          // this.$router.push({ name: "Index2" });
        }
        if (this.routejump == true && this.routearbitratjump == true) {
          this.$router.push({ name: "CaseDetail", query: { id: this.id } });
        }
      }
      if (key == 0) {
        this.getArbitration()
        if (this.routearbitratjump == true) {
          if ($route == "/arbitratdetail") {
            this.$router.push({ name: "ArbitratDetail", query: { id: this.uuid }  });
          }
        } else {
          // this.$router.push({ name: "Index2" });
        }

        if (this.routejump == true && this.routearbitratjump == true) {
          this.$router.push({ name: "ArbitratDetail", query: { id: this.uuid } });
        }
      }
    },

    // 仲裁案件列表分页
    arbitratHandleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.arbitratPagesize = val;
    },
    arbitratHandleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.arbitratCurrentPage = val;
    },

    arbitratPrev() {
      let arbitratListLength =
        parseInt(this.arbitratList.length / this.arbitratPagesize) + 1;
      if (arbitratListLength == 1) {
        this.arbitratPagePrevBtn = true;
        this.arbitratPageNextBtn = true;
      } else {
        this.arbitratCurrentPage--;
        if (this.arbitratCurrentPage < 1 || this.arbitratCurrentPage == 1) {
          this.arbitratCurrentPage = 1;
          this.arbitratPagePrevBtn = true;
          this.arbitratPageNextBtn = false;
        } else {
          this.arbitratPageNextBtn = false;
          this.arbitratPagePrevBtn = false;
        }
      }

      // console.log(this.currentPage);
    },
    arbitratNext() {
      let arbitratListLength =
        parseInt(this.arbitratList.length / this.arbitratPagesize) + 1;
      if (arbitratListLength == 1) {
        this.arbitratPagePrevBtn = true;
        this.arbitratPageNextBtn = true;
      } else {
        this.arbitratCurrentPage++;
        if (this.arbitratCurrentPage > arbitratListLength) {
          this.arbitratCurrentPage = arbitratListLength;
          this.arbitratPageNextBtn = true;
          this.arbitratPagePrevBtn = false;
        } else if (this.arbitratCurrentPage == arbitratListLength) {
          this.arbitratPageNextBtn = true;
          this.arbitratPagePrevBtn = false;
        } else {
          this.arbitratPageNextBtn = false;
          this.arbitratPagePrevBtn = false;
        }
      }
    },

    // 调节案件列表分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagesize = val;
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
    },

    prev() {
      let mdiationListLength =
        parseInt(this.mdiationList.length / this.pagesize) + 1;
      if (mdiationListLength == 1) {
        this.pagePrevBtn = true;
        this.pageNextBtn = true;
      } else {
        this.currentPage--;
        if (this.currentPage < 1 || this.currentPage == 1) {
          this.currentPage = 1;
          this.pagePrevBtn = true;
          this.pageNextBtn = false;
        } else {
          this.pageNextBtn = false;
          this.pagePrevBtn = false;
        }
      }

      // console.log(this.currentPage);
    },
    next() {
      let mdiationListLength =
        parseInt(this.mdiationList.length / this.pagesize) + 1;
      if (mdiationListLength == 1) {
        this.pagePrevBtn = true;
        this.pageNextBtn = true;
      } else {
        this.currentPage++;
        console.log(this.currentPage, mdiationListLength);
        if (this.currentPage > mdiationListLength) {
          this.currentPage = mdiationListLength;
          this.pageNextBtn = true;
          this.pagePrevBtn = false;
        } else if (this.currentPage == mdiationListLength) {
          this.pageNextBtn = true;
          this.pagePrevBtn = false;
        } else {
          this.pageNextBtn = false;
          this.pagePrevBtn = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/index2.scss";
</style>